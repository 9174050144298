<template>
  <div v-cloak>
    <div class="notification server-down-message is-danger" v-if="show_server_error">
      <button class="delete" @click="show_server_error=false"></button>
      {{lc('server_down.title')}}.
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data(){
    return {
      isRTL:true,
      server_check_interval:null,
      show_server_error:false
    }
  },
  methods:{
    changeLocal(){
      this.$store.commit('changeLocal');
      window.location.reload()
    }
  },
  mounted(){
    document.title = this.lc('main.title');
    // this.$store.commit('configs');
    // this.$store.commit('auth');
  },
  created() {
    this.$store.commit('configs');
    this.$store.commit('auth');
    this.$store.watch((state)=>state.server_down,(server_down) => {
      console.log('server_down value change',server_down)
      if(server_down && this.server_check_interval == null){
        this.show_server_error = true
        this.server_check_interval = setInterval(()=>{
          this.show_server_error = true
          this.$store.commit('configs');
        },10000)
      }else if(!server_down && this.server_check_interval != null){
        clearInterval(this.server_check_interval);
        this.server_check_interval = null
      }
      if(!server_down){
        this.$store.commit('configs');
        this.$store.commit('auth');
      }
    },true)
    if(this.$store.state.isRTL)
      import("@/assets/app-rtl.scss");
    else
      import("@/assets/app.scss");
  },
  computed: {
    ...mapGetters([
      'lc',
    ])
    // lc(label){
    //   return this.$store.getters.lc(label)
    // }
  }
}
</script>

<style lang="scss">
// @font-face {
//     font-family: 'ArefRuqaa';
//     src: url('/fonts/ArefRuqaa-Regular.ttf');
// }
// * {
//   font-family: 'ArefRuqaa';
// }
html{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
// html {
//   overflow: hidden !important; /* Hide scrollbars */
// }
[v-cloak]{ display:none }
.server-down-message {
    position: fixed !important;
    z-index: 99;
    width: 40%;
    margin: 5vh auto;
    left: 0;
    right: 0;
}
@media screen and (max-height: 630px) {
  .server-down-message {
    width: 60%;
  }
}
</style>