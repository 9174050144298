<template>
  <!-- <span class="icon has-text-white	"> -->
      <svg viewBox="0 0 24 24`" :fill="color" :height="size" :width="size" >
        <path :d="icon"></path>  
      </svg>
    <!-- </span> -->
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiUfoOutline } from '@mdi/js';
export default {
  name: 'Icon',
  props:{
    size:{
      type:Number,
      default:24
    },
    icon:{
      type:String,
      default:mdiUfoOutline
    },
    color:{
      type:String,
      default:"white"
    }
  },
  computed: {
    ...mapGetters([
      'lc',
    ])
  }
}
</script>

