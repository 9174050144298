<template>
  <div class="login-page">
    <NavBar :hideSignUp="true" :hideLogin="auth" :showLogout="auth"/>
    <section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <article v-if="!canInvite()" class="message is-info">
        <div class="message-header">
          {{lc('common.redirect_in_5sec_to_login_title')}}
        </div>
        <div class="message-body">
          {{lc('common.redirect_in_5sec_to_login_body')}}
          <button class="button is-light is-rounded is-loading"></button>
        </div>
      </article>
      <div class="columns is-centered">
        <div class="column is-5-tablet is-5-desktop is-5-widescreen">
          <form action="" class="box" @submit.prevent="">
            <fieldset :disabled="!canInvite()">
            <div class="field">
              <label for="" class="label">{{lc('common.email')}}</label>
              <div class="control has-icons-left">
                <input type="email" class="input" required v-model="email">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <button class="button is-success is-fullwidth" @click="invite()">
                {{lc('auth.invite')}}
              </button>
            </div>
            </fieldset>
          </form>
          <div v-if="invetation_link!=null">
            <div class="field has-addons">
              <div class="control" style="width: 100%;">
                <input class="input" type="text" placeholder="Find a repository" v-model="this.invetation_link" readonly>
              </div>
              <div class="control">
                <a class="button" @click="copyButton()">
                  <Icon :icon="icons.mdiContentCopy" :size="24" :color="'gray'" />
                </a>
              </div>
              <div class="control">
                <a class="button is-info" @click="shareButton()">
                  <Icon :icon="icons.mdiShareVariant" :size="24" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>

<script>
  import NavBar from './NavBar.vue'
  import { mdiShareVariant,mdiContentCopy} from '@mdi/js'
  import Icon from './Icon.vue'
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'Invite',
    data(){
      return {
        email:"",
        processing:false,
        auth:false,
        done:false,
        auth_loading:false,
        invetation_link:null,
        user:{},
        icons:{mdiShareVariant,mdiContentCopy}
      }
    },
    components: {
      NavBar,Icon
    },
    methods:{
      copyButton(){
      navigator.clipboard.writeText(this.invetation_link)
      },
      shareButton(){
        navigator.share({
          title: this.lc('main.title'),
          text: this.lc('invetation'),
          url: this.invetation_link
        })
      },
      validateForm(){
        return true;
      },
      invite(){
        if(this.processing)return;

        let data = new FormData();
        data.append("email", this.email)
        this.processing = false
        this.$store.dispatch('invite',data).then((r)=>{
          this.done = r.done;
          this.invetation_link = r.invetation_link
          this.processing = false;
        }).catch(()=>{
          this.processing = false;
        })
      },
      canInvite(){
        return this.auth && this.user.type =="admin"
      },
      redirect_if_auth_home(){
        console.log((!this.auth || this.user.type !="admin"),this.auth , this.user)
        if(!this.canInvite()){
          setTimeout(() => {
            this.$router.push('/')
          }, 5000);
        }
      }
    },
    mounted(){
      this.auth = this.$store.state.auth;
      this.auth_loading = this.$store.state.loading.auth;
      this.user = this.$store.state.user;
      if(this.auth_loading){
        this.$store.watch((state)=>state.loading.auth,(auth_loading) => {
          this.auth_loading = auth_loading;
          this.auth = this.$store.state.auth;
          this.user = this.$store.state.user;
          this.redirect_if_auth_home()
        });
      }else{
        this.redirect_if_auth_home()
      }
      this.$store.watch((state)=>state.user,(user) => {
        this.user = user;
      });
    },
    computed: {
      ...mapGetters([
        'lc',
      ])
    }
  }
</script>
<style scoped>
.room-list{
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
}
.room-list::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
</style>