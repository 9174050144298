<template>
  <div class="login-page">
    <NavBar :hideSignUp="true"/>
    <section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <article v-if="disabled" class="message is-danger">
        <div class="message-header">
          {{lc('auth.signup_disaled_title')}}
        </div>
        <div class="message-body">
          {{lc('auth.signup_disaled_body')}}
        </div>
      </article>
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <form action="" class="box" @submit.prevent="">
            <fieldset :disabled="disabled">
            <div class="field">
              <label for="" class="label">{{lc('common.id')}}</label>
              <div class="control has-icons-left">
                <input type="text" class="input" required v-model="id">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">{{lc('common.name')}}</label>
              <div class="control has-icons-left">
                <input type="text" class="input" autocomplete="name" required v-model="name">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">{{lc('common.email')}}</label>
              <div class="control has-icons-left">
                <input type="email" class="input" autocomplete="email" required v-model="email">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">{{lc('common.password')}}</label>
              <div class="control has-icons-left">
                <input type="password" name="password" class="input" autocomplete="new-password" required v-model="password">
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">{{lc('common.confirm_password')}}</label>
              <div class="control has-icons-left">
                <input type="password" name="password" class="input" autocomplete="new-password" required v-model="confirm_password">
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="checkbox">
                <input type="checkbox">
               {{lc('common.accept_legel')}}
              </label>
            </div>
            <div class="field">
              <button class="button is-success is-fullwidth" @click="signup()">
                {{lc('auth.signup')}}
              </button>
            </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>

<script>
  import NavBar from './NavBar.vue'
  import {
    mapGetters
  } from 'vuex'
  
  export default {
    name: 'SignUp',
    data(){
      return {
        disabled:true,
        confirm_password:"",
        password:"",
        name:"",
        email:"",
        id:"",
        processing:false
      }
    },
    components: {
      NavBar
    },
    methods:{
      validateForm(){
        return true;
      },
      signup(){
        if(this.disabled || this.processing)return;

        let data = new FormData();
        data.append("id", this.id)
        data.append("email", this.email)
        data.append("password", this.password)
        data.append("type", "user")
        data.append("name", this.name)
        this.processing = false
        this.$store.dispatch('register',data).then((r)=>{
          if(r.auth){
            this.$router.push('/')
          }
          this.processing = false;
        }).catch(()=>{
          this.processing = false;
        })
      }
    },
    mounted(){
      console.log('reg','mounted',this.$store.state.serverConfig)
      this.disabled = !this.config().registration;
      this.$store.watch((state)=>state.config,(config) => {
       console.log('reg','state-change',config)
       this.disabled = !config.registration;
      });
    },
    computed: {
      ...mapGetters([
        'lc',
        'config',
      ])
    }
  }
</script>
<style scoped>

</style>