export default{
    methods:{
        setTracks(peer_data){
            let _video_track = null
            let _video_screen_tracks = []
            let _audio_track = null
            if(this.streams.video != null){
              _video_track = this.streams.video.getTracks()[0];
            }
            if(this.streams.video_screen != null){
              _video_screen_tracks = [..._video_screen_tracks, ...this.streams.video_screen.getTracks()];
            }
            if(this.streams.audio != null){
              _audio_track = this.streams.audio.getTracks()[0];
            }
            console.log("RTC setTracks => peer = ",this.peers[peer_data.id])
            // let localstream = new MediaStream([..._video_tracks,..._audio_tracks,..._video_screen_tracks])
            let set_video_flag = this.streams.video != null && this.toggels.video;
            let set_audio_flag = this.streams.audio != null && this.toggels.mic;
            let set_video_screen_flag = this.streams.video_screen != null && this.toggels.persentscreen;

            if(set_video_flag){
              if(this.peers[peer_data.id].streams.video == null){
                this.peers[peer_data.id].streams.video = new MediaStream([_video_track])
              }else{
                this.peers[peer_data.id].streams.video.removeTrack(this.peers[peer_data.id].streams.video.getTracks()[0])
                this.peers[peer_data.id].streams.video.addTrack(_video_track)
              }
              if (typeof this.peers[peer_data.id].tracks.video[0] === 'undefined') {
                this.peers[peer_data.id].tracks.video[0] = this.peers[peer_data.id].webrtc_conn.addTrack(_video_track,this.peers[peer_data.id].streams.video)
              } else {
                this.peers[peer_data.id].tracks.video[0].replaceTrack(_video_track)
              }
            }else{
              // peer.tracks.video.forEach(track_sender => {
              //   peer.webrtc_conn.removeTrack(track_sender)
              // });
              // peer.tracks.video = []
            }
            if(set_audio_flag){
              if(this.peers[peer_data.id].streams.audio == null){
                this.peers[peer_data.id].streams.audio = new MediaStream([_audio_track])
              }else{
                this.peers[peer_data.id].streams.audio.removeTrack(this.peers[peer_data.id].streams.audio.getTracks()[0])
                this.peers[peer_data.id].streams.audio.addTrack(_audio_track)
              }
              if (typeof this.peers[peer_data.id].tracks.audio[0] === 'undefined') {
                this.peers[peer_data.id].tracks.audio[0] = this.peers[peer_data.id].webrtc_conn.addTrack(_audio_track,this.peers[peer_data.id].streams.audio)
              } else {
                this.peers[peer_data.id].tracks.audio[0].replaceTrack(_audio_track)
              }
            }else{
              // peer.tracks.audio.forEach(track_sender => {
              //   peer.webrtc_conn.removeTrack(track_sender)
              // });
              // peer.tracks.audio = []
            }
            if(set_video_screen_flag){
              if(this.peers[peer_data.id].streams.video_screen == null){
                this.peers[peer_data.id].streams.video_screen = new MediaStream([..._video_screen_tracks])
              }else{
                this.peers[peer_data.id].streams.video_screen.getTracks().forEach(t=>this.peers[peer_data.id].streams.video_screen.removeTrack(t))
                _video_screen_tracks.forEach(t=>this.peers[peer_data.id].streams.video_screen.addTrack(t))
              }
              _video_screen_tracks.forEach((track,i) => {
                if (typeof this.peers[peer_data.id].tracks.video_screen[i] === 'undefined') {
                  this.peers[peer_data.id].tracks.video_screen[i] = this.peers[peer_data.id].webrtc_conn.addTrack(track,this.peers[peer_data.id].streams.video_screen)
                } else {
                  this.peers[peer_data.id].tracks.video_screen[i].replaceTrack(track)
                }
                console.log(`video_screen[${i}].track(${track})`)
              });
            }else{
              // peer.tracks.video_screen.forEach(track_sender => {
              //   peer.webrtc_conn.removeTrack(track_sender)
              // });
              // peer.tracks.video_screen = []
            }
            
          },
          toggelEndMeeting(){
            this.webscoket.close();
            for(let k in this.peers){
              let peer = this.peers[k]
              peer.webrtc_conn.close();
            }
            window.location = "/"
          },
          toggelMic(){
            if(!this.permissions.audio)return
            this.toggels.mic = !this.toggels.mic
            // this.updateStreamsDevices()
            if(this.streams.audio != null){
              for(let k in this.peers){
                let peer = this.peers[k]
                console.log("toggelVideo",peer,peer.streams.audio,this.toggels.mic)
                if(peer.info.active){
                  peer.streams.audio.getTracks().forEach(track => track.enabled = this.toggels.mic)
                }
              }
            }else{
              this.updateStreamsDevices()
            }
          },
          toggelVideo(){
            if(!this.permissions.video)return
            this.toggels.video = !this.toggels.video

            if(this.toggels.video){
              this.updateStreamsDevices()
            }else{
              if(this.streams.video != null)
                this.streams.video.getTracks().forEach(track => track.stop())
                this.streams.video=null
            }
          },
          toggelPresentscreen(){
            this.toggels.persentscreen = !this.toggels.persentscreen
            
            // if(this.toggels.video)this.toggelVideo();
            if(this.toggels.persentscreen){
              this.updateStreamsDevices()
            }else{
              if(this.streams.video_screen != null)
                this.streams.video_screen.getTracks().forEach(track => track.stop())
                this.streams.video_screen=null
            }
            
          },
          toggelShare(){
            this.toggels.sharescreen = !this.toggels.sharescreen
          },
          toggelSettings(){
            this.toggels.settingscreen = !this.toggels.settingscreen
          },
          toggelFullscreen(){
            this.toggels.fullscreen = !this.toggels.fullscreen
            let elem = document.documentElement;
            if(!elem.onfullscreenchange)
              elem.onfullscreenchange = ()=>{
                this.toggels.fullscreen = document.fullscreenElement !== null;
              }
            /* View in fullscreen */
            if(this.toggels.fullscreen) {
              if (elem.requestFullscreen) {
                elem.requestFullscreen();
              } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
              } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
              }
            }else{ /* Close fullscreen */
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
              }
            }
    
          },
          toggelFullscreenPeer(peer_id){
            this.peers[peer_id].lstatus.full_screen = !this.peers[peer_id].lstatus.full_screen
          },
          updateStreams(){
            console.log("updateStreams",this.permissions,this.toggels)
            let promises = [];
            if(this.streams.audio==null && this.permissions.audio && this.toggels.mic){ //&& this.selected.mic
              let prms = navigator.mediaDevices.getUserMedia({ audio: (this.selectedMediaDevices.mic == null)?true:{deviceId:{exact: this.selectedMediaDevices.mic}} }).then((stream)=>{
                this.streams.audio = stream;
              }).catch((err)=>{
                console.log("updateStreams-audio",err)
              });
              promises.push(prms)
            }else{
              // if(this.streams.audio != null)
              //   this.streams.audio.getTracks().forEach(track => track.stop())
            }
            if(this.streams.video==null && this.permissions.video && this.toggels.video){ //&& this.selected.video
              let prms = navigator.mediaDevices.getUserMedia({ video: (this.selectedMediaDevices.video == null)?true:{deviceId:{exact: this.selectedMediaDevices.video}} }).then((stream)=>{
                this.streams.video = stream;
              }).catch((err)=>{
                console.log("updateStreams-video",err)
              });
              promises.push(prms)
            }else{
              // if(this.streams.video != null)
              //   this.streams.video.getTracks().forEach(track => track.stop())
            }
            if(this.streams.persentscreen==null && this.toggels.persentscreen && this.streams.video_screen == null){
                let prms = navigator.mediaDevices.getDisplayMedia({video: true}).then((stream)=>{
                  this.streams.video_screen = stream
                  stream.getTracks().forEach(t=>{t.onended = ()=>{
                    this.toggels.persentscreen = false
                    this.streams.video_screen = null
                  }})
                }).catch((err)=>{
                  console.log(err)
                  this.toggels.persentscreen = false
                });
                promises.push(prms)
              }else if(!this.toggels.persentscreen){
                if(this.streams.video_screen != null)
                  this.streams.video_screen.getTracks().forEach(track => track.stop())
              }
            return Promise.all(promises)
          },
          updateStreamsDevices(){
            console.log("Update all the streams in for all the peers",this.peers)
            this.updateStreams().then(()=>{
              for(let k in this.peers){
                let peer = this.peers[k]
                if(peer.info.active && peer.has_tracks){
                  this.setTracks(peer.info)
                }
              }
            })
          },
    }
}