<template>
  <!-- <span class="icon has-text-white	"> -->
      <div class="modal" :class="{'is-active':isactiveRun}">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{lc("settings.title")}}</p>
            <button class="delete" aria-label="close" @click="closeModleSafe()"></button>
          </header>
          <section class="modal-card-body">
            <div class="columns is-flex is-vcentered is-centered is-multiline is-mobile" v-if="permissions.video || permissions.audio">
              <div class="column has-text-centered is-12" v-if="permissions.video"><video ref="video_local_play" controls nodownload nofullscreen noremoteplayback noplaybackrate disablepictureinpicture  autoplay></video></div>
              <div class="column has-text-centered is-12" v-if="permissions.audio"><audio ref="audio_local_play" controls noremoteplayback noplaybackrate autoplay></audio></div>
              <div class="column has-text-centered is-12 buttons">
                <button class="button is-primary" @click="playTestLocalStreams()">{{lc('settings.start_test')}}</button>
                <button class="button" @click="closeStreams()">{{lc('settings.stop_test')}}</button>
              </div>
            </div>
            <article class="message is-danger is-large" v-if="hasPermissions">
              <div class="message-body">
                {{lc("settings.no_devices_or_permission")}}
              </div>
            </article>
            <div class="field" v-else>
              <label class="label" v-if="len(devices.mics)>0">{{lc("settings.mics_devices")}}</label>
              <div class="select" v-if="len(devices.mics)==0">
                <select disabled>
                  <option>{{lc('settings.no_device_found')}}</option>
                </select>
              </div>
              <div class="select" v-if="len(devices.mics)>0">
                <select v-model="selected.mic">
                  <option v-for="mic in devices.mics" :key="mic" :value="mic.id">{{mic.label}}</option>
                </select>
              </div>

              <label class="label">{{lc("settings.video_devices")}}</label>
              <div class="select" v-if="len(devices.video)==0">
                <select disabled>
                  <option>{{lc('settings.no_device_found')}}</option>
                </select>
              </div>
              <div class="select" v-if="len(devices.video)>0">
                <select v-model="selected.video">
                  <option v-for="videosource in devices.video" :key="videosource" :value="videosource.id">{{videosource.label}}</option>
                </select>
              </div>

              <label class="label">{{lc("settings.speakers_devices")}}</label>
              <div class="select" v-if="len(devices.speakers)==0">
                <select disabled>
                  <option>{{lc('settings.no_device_found')}}</option>
                </select>
              </div>
              <div class="select" v-if="len(devices.speakers)>0">
                <select v-model="selected.speaker">
                  <option v-for="speaker in devices.speakers" :key="speaker" :value="speaker.id">{{speaker.label}}</option>
                </select>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" @click="closeModleSave()">{{lc("settings.savecahnges")}}</button>
            <button class="button" @click="closeModleSafe()">{{lc("settings.cancel")}}</button>
          </footer>
        </div>
      </div>
    <!-- </span> -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SettingsModal',
  data(){
    return {
      devices:{
        mics:[],
        video:[],
        speakers:[]
      },
      selected:{
        mic:null,
        video:null,
        speaker:null,
        update_flage:false
      },
      streams:{
        audio:null,
        video:null
      }
    }
  },
  props:{
    isactive:{
      type:Boolean,
      default:false
    },
    closeModal:{
      type:Function,
      default:()=>{console.log('closing down')}
    },
    permissions:{
      type:Object,
      default(){
        return {
          audio:false,
          video:false
        }
      }
    }
  },
  watch: {
    "selected.mic"(){this.playTestLocalStreams()},
    "selected.video"(){this.playTestLocalStreams()},
    "selected.speaker"(){this.playTestLocalStreams()},
  },
  methods:{
    closeStreams(){
      try{
        this.streams.audio.getTracks().forEach((track)=>{track.stop()});
        this.$refs['audio_local_play'].srcObject = null;
      }catch(e){/**/}
      try{
        this.streams.video.getTracks().forEach((track)=>{track.stop()})
        this.$refs['video_local_play'].srcObject = null;
      }catch(e){/**/}
      this.streams = {audio:null,video:null}
    },
    closeModleSafe(){
      this.$store.state.selectedMediaDevices = this.selected
      this.closeStreams()
      this.closeModal(this.selected)
    },
    closeModleSave(){
      this.$store.commit('changeMediaDevices',this.selected);
      this.closeModleSafe()
    },
    len(list){
      return list.length
    },
    playTestLocalStreams(){
      if(!this.isactive) return;
      this.closeStreams()
      this.updateDefultSelect();
      if(this.permissions.audio && this.selected.mic){
        navigator.mediaDevices.getUserMedia({ audio: {deviceId:{exact: this.selected.mic}} }).then((stream)=>{
          this.streams.audio = stream;
          this.$refs['audio_local_play'].srcObject = stream;
          if(this.selected.speaker)this.$refs['audio_local_play'].setSinkId(this.selected.speaker)
        }).catch((err)=>{
          console.log(err)
        });
      }
      if(this.permissions.video && this.selected.video){
        navigator.mediaDevices.getUserMedia({ video: {deviceId:{exact: this.selected.video},width: {exact: 320}, height: {exact: 240}} }).then((stream)=>{
          this.streams.video = stream;
          this.$refs['video_local_play'].srcObject = stream;
          if(this.selected.speaker)this.$refs['video_local_play'].setSinkId(this.selected.speaker)
        }).catch((err)=>{
          console.log(err)
        });
      }
    },
    updateDefultSelect(){
      if(!this.selected.mic && this.devices.mics.length>0)this.selected.mic = this.devices.mics[0].id;
      if(!this.selected.video && this.devices.video.length>0)this.selected.video = this.devices.video[0].id;
      if(!this.selected.speaker && this.devices.speakers.length>0)this.selected.speaker = this.devices.speakers[0].id;
      // this.$store.commit('changeMediaDevices',this.selected);
      // this.$store.state.selectedMediaDevices = this.selected
    },
    updateDevicesList(){
      if(!navigator.mediaDevices){
        return false
      }
      this.devices = {
        mics:[],
        video:[],
        speakers:[]
      }
      navigator.mediaDevices.enumerateDevices().then((d_infos)=>{
        // console.log('found device',d_infos,this.devices)
        d_infos.forEach(d_info => {
          switch (d_info.kind) {
            case "videoinput":this.devices.video.push({'id':d_info.deviceId,'label':d_info.label});break;
            case "audioinput":this.devices.mics.push({'id':d_info.deviceId,'label':d_info.label});break;
            case "audiooutput":this.devices.speakers.push({'id':d_info.deviceId,'label':d_info.label});break;
          }
        })
        this.updateDefultSelect()
      }).catch((err)=>{
        console.log(err)
      });
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.updateDevicesList()
    })
  },
  computed: {
    isactiveRun(){
      this.updateDevicesList()
      return this.isactive;
    },
    hasPermissions(){
      this.updateDevicesList()
      return !this.permissions.audio&&!this.permissions.video
    },
    ...mapGetters([
      'lc',
    ])
  }
}
</script>
<style scoped>
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    display: none;
}
audio::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {}
video::-webkit-media-controls-timeline {
   display: none;
}
video::-webkit-media-controls-current-time-display{
   display: none;
}
video::-webkit-media-controls-time-remaining-display {
   display: none;
}
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
   display: none;
}
video::-webkit-media-controls-volume-slider {
  display: none;
}
</style>