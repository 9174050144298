<template>
  <!-- <span class="icon has-text-white	"> -->
      <div class="modal" :class="{'is-active':isactiveRun}">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{lc("share.title")}}</p>
            <button class="delete" aria-label="close" @click="closeModal()"></button>
          </header>
          <section class="modal-card-body">
            <div class="field has-addons">
              <div class="control" style="width: 100%;">
                <input class="input" type="text" placeholder="Find a repository" v-model="this.link" readonly>
              </div>
              <div class="control">
                <a class="button" @click="copyButton()">
                  <Icon :icon="icons.mdiContentCopy" :size="24" :color="'gray'" />
                </a>
              </div>
              <div class="control">
                <a class="button is-info" @click="shareButton()">
                  <Icon :icon="icons.mdiShareVariant" :size="24" />
                </a>
              </div>
            </div>
            <div v-html="code_svg"></div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" @click="closeModal()">{{lc("share.cancel")}}</button>
          </footer>
        </div>
      </div>
    <!-- </span> -->
</template>

<script>
import { mdiShareVariant,mdiContentCopy} from '@mdi/js'
import Icon from './Icon.vue'
import QRCode from 'qrcode'
import { mapGetters } from 'vuex'
export default {
  name: 'ShareModal',
  data(){
    return {
      code_svg:null,
      link:"",
      icons:{mdiShareVariant,mdiContentCopy}
    }
  },
  components: {
      Icon
  },
  props:{
    isactive:{
      type:Boolean,
      default:false
    },
    roomID:{
      type:String,
      default:""
    },
    room:{
      type:Object,
      default(){
        return {
          "id": "",
          "user": {
            "id": "",
            "email": "",
            "name": "",
            "type": "",
            "lastupdate": ""
          },
          "started": false,
          "description": "",
          "lastupdate": ""
        }
      }
    },
    closeModal:{
      type:Function,
      default:()=>{console.log('closing down share')}
    }
  },
  methods:{
    copyButton(){
      navigator.clipboard.writeText(this.link)
    },
    shareButton(){
      navigator.share({
        title: this.lc('main.title') + ' | ' + this.room.user.name,
        text: this.room.description,
        url: this.link
      })
    }
  },
  mounted(){
    this.link = window.location.href
    QRCode.toString(this.link,{type:'svg'}, (err, string)=>{
      if (err) throw err
      this.code_svg = string
    })
  },
  computed: {
    isactiveRun(){
      return this.isactive;
    },
    ...mapGetters([
      'lc',
    ])
  }
}
</script>
<style scoped>
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    display: none;
}
audio::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {}
video::-webkit-media-controls-timeline {
   display: none;
}
video::-webkit-media-controls-current-time-display{
   display: none;
}
video::-webkit-media-controls-time-remaining-display {
   display: none;
}
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
   display: none;
}
video::-webkit-media-controls-volume-slider {
  display: none;
}
</style>