<template>
  <div class="login-page">
    <NavBar :hideSignUp="true" />
    <section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <h1></h1>
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <h1 class="title">{{lc('page_not_found.title')}}</h1>
          <p class="subtitle">{{lc('page_not_found.message')}}</p>
          <a class="button is-primary" href="/">{{lc('page_not_found.home_button')}}</a>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>
<script>

  import NavBar from './NavBar.vue'
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'PageNotFound',
    components: {
      NavBar
    },
    data(){
      return {
        
      }
    },
    computed: {
      ...mapGetters([
        'lc',
      ])
    }
  }
</script>
<style scoped>

</style>