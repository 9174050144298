<template>
    <nav class="navbar is-transparent" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item"><img :src="'/assets/'+lc('logo')"></router-link>
        <a role="button" :class="{'navbar-burger':true,'is-active':togglenavbar}" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="toggleNavMenu()">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" :class="{'is-active':togglenavbar}">
        <div class="navbar-start">
          <!-- <router-link to="/" class="navbar-item">Home</router-link> -->
          <!-- <router-link to="/" class="navbar-item">{{lc('main.title')}}</router-link> -->
        </div>

        <div class="navbar-end">

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-item is-size-3 is-active">
              🌍
            </a>

            <div class="navbar-dropdown">
              <a class="navbar-item" @click="cahngeLang('ar')">
                العربية
              </a>
              <a class="navbar-item" @click="cahngeLang('en')">
                English
              </a>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <router-link v-if="!hideSignUp" to="/signup" class="button is-light">{{lc('auth.signup')}}</router-link>
              <router-link v-if="!hideLogin" to="/login" class="button is-primary" :class="{'is-loading':auth_loading}">{{lc('auth.login')}}</router-link>
              <router-link v-if="showLogout" to="/profile" class="button is-light">{{user.name}}</router-link>
              <router-link v-if="user.type=='admin'" to="/invite" class="button is-info">{{lc('auth.invite')}}</router-link>
              <button v-if="showLogout" class="button is-primary" @click="logout()">{{lc('auth.logout')}}</button>
            </div>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  props:{
    hideLogin:{
      type:Boolean,
      default:false
    },
    hideSignUp:{
      type:Boolean,
      default:false
    },
    showLogout:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      togglenavbar:false,
      auth:false,
      user:{},
      auth_loading:false
    }
  },
  methods:{
    toggleNavMenu(){
      console.log('nav toggled',this.togglenavbar)
      this.togglenavbar = !this.togglenavbar;
    },
    cahngeLang(lang){
      this.$store.commit('changeLocal',lang);
      location.reload()
    },
    logout(){
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
  },
  mounted(){
    this.auth_loading = this.$store.state.loading.auth;
    if(this.auth_loading){
      this.$store.watch((state)=>state.loading.auth,(auth_loading) => {
        this.auth_loading = auth_loading;
      });
    }
    this.user = this.$store.state.user;
    this.$store.watch((state)=>state.user,(user) => {
      this.user = user;
    });
    
  },
  computed: {
    ...mapGetters([
      'lc',
    ])
  }
}
</script>

