<template>
  <div class="login-page">
    <NavBar :hideSignUp="true" :hideLogin="auth" :showLogout="auth"/>
    <section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <article v-if="!auth && !auth_loading" class="message is-info">
        <div class="message-header">
          {{lc('common.redirect_in_5sec_to_login_title')}}
        </div>
        <div class="message-body">
          {{lc('common.redirect_in_5sec_to_login_body')}}
          <button class="button is-light is-rounded is-loading"></button>
        </div>
      </article>
      <div class="columns is-centered">
        <div class="column is-4-tablet is-6-desktop is-6-widescreen room-list" v-if="rooms.length > 0">
          <div class="card mb-2" v-for="room in rooms" :key="room">
            <div class="card-content">
              <div class="content">
                <h5><router-link :to="{ name: 'CallPage', params: { meetingID: room.id }}">{{room.id}}</router-link></h5>
                <br>
                <time>{{dateFormate(room.lastupdate)}}</time>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-4-tablet is-6-desktop is-6-widescreen">
          <form action="" class="box" @submit.prevent="">
            <fieldset :disabled="!auth">
            <div class="field">
              <label for="" class="label">{{lc('common.room_id')}}</label>
              <div class="control has-icons-left">
                <input type="text" class="input" required v-model="room">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <label for="" class="label">{{lc('common.password')}}</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input :type="(show_password)?'text':'password'" name="password" class="input" autocomplete="new-password" required v-model="password">
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
              <div class="control" @click="showPassword()">
                <a class="button is-static">
                  <Icon :icon="icons.mdiEyeOff" v-if="!show_password" :size="24" :color="'black'"/>
                  <Icon :icon="icons.mdiEye" v-else :size="24" :color="'black'"/>
                </a>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">{{lc('common.room_desc')}}</label>
              <div class="control has-icons-left">
                <textarea class="textarea" required v-model="desc"></textarea>
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <button class="button is-success is-fullwidth" @click="schedule()">
                {{lc('common.schedule')}}
              </button>
            </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>

<script>
  import NavBar from './NavBar.vue'
  import Icon from './Icon.vue'
  import { mdiEyeOff,mdiEye } from '@mdi/js';
  import {
    mapGetters
  } from 'vuex'
  import moment from 'moment';

  export default {
    name: 'Schedule',
    data(){
      return {
        room:"",
        password:"",
        show_password:false,
        desc:"",
        processing:false,
        auth:false,
        done:false,
        auth_loading:false,
        rooms:[],
        icons:{
          mdiEyeOff,
          mdiEye
        }
      }
    },
    components: {
      NavBar,
      Icon
    },
    methods:{
      validateForm(){
        return true;
      },
      showPassword(){
        this.show_password = !this.show_password;
      },
      dateFormate(date){
        moment.locale(this.$store.state.selectedLocal)
        // return moment(date).format('LLLL')
        return moment(date).format('l LT')
      },
      schedule(){
        if(this.processing)return;

        let data = new FormData();
        data.append("room", this.room)
        data.append("password", this.password)
        data.append("desc", this.desc)
        this.processing = false
        this.$store.dispatch('rschedule',data).then((r)=>{
          this.done = r.done;
          console.log(r)
          this.processing = false;
        }).catch(()=>{
          this.processing = false;
        })
      },
      redirect_if_auth_home(){
        if(!this.auth){
          setTimeout(() => {
            this.$router.push('/login')
          }, 5000);
        }else{
          this.$store.dispatch('myrooms').then((data)=>{
            if(typeof data.rooms !== 'undefined' && data.rooms != null){
              this.rooms = data.rooms;
            }
          })
        }
      }
    },
    mounted(){
      this.auth = this.$store.state.auth;
      this.auth_loading = this.$store.state.loading.auth;
      if(this.auth_loading){
        this.$store.watch((state)=>state.loading.auth,(auth_loading) => {
          this.auth_loading = auth_loading;
          this.auth = this.$store.state.auth;
          this.redirect_if_auth_home()
        });
      }else{
        this.redirect_if_auth_home()
      }
    },
    computed: {
      ...mapGetters([
        'lc',
      ])
    }
  }
</script>
<style scoped>
.room-list{
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
}
.room-list::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
</style>