<template>
  <div class="login-page">
    <NavBar :hideLogin="auth" :showLogout="auth" :hideSignUp="true"/>
    <section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <article v-if="!auth && !auth_loading" class="message is-info">
        <div class="message-header">
          {{lc('common.redirect_in_5sec_to_login_title')}}
        </div>
        <div class="message-body">
          {{lc('common.redirect_in_5sec_to_login_body')}}
          <button class="button is-light is-rounded is-loading"></button>
        </div>
      </article>
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <form action="" class="box" @submit.prevent="">
            <fieldset :disabled="!auth">
            <label for="" class="label">{{lc('common.password_room')}}</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input :type="(show_password)?'text':'password'" name="password" class="input" autocomplete="new-password" required v-model="password">
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
              <div class="control" @click="showPassword()">
                <a class="button is-static">
                  <Icon :icon="icons.mdiEyeOff" v-if="!show_password" :size="24" :color="'black'"/>
                  <Icon :icon="icons.mdiEye" v-else :size="24" :color="'black'"/>
                </a>
              </div>
            </div>
            <div class="field" v-if="room==null">
              <label for="" class="label">{{lc('common.room_desc')}}</label>
              <div class="control has-icons-left">
                <textarea class="textarea" required v-model="desc"></textarea>
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <button class="button is-success is-fullwidth" :class="{'is-loading':processing}" @click="login()">
                {{(room == null)?lc('common.create_room'):lc('auth.login')}}
              </button>
            </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>
<script>

  import NavBar from './NavBar.vue'
  import Icon from './Icon.vue'
  import { mdiEyeOff,mdiEye } from '@mdi/js';
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'LoginRoom',
    components: {
      NavBar,
      Icon
    },
    data(){
      return {
        id:'',
        password:'',
        desc:'',
        show_password:false,
        room:{},
        processing:false,
        auth:false,
        auth_loading:false,
        icons:{
          mdiEyeOff,
          mdiEye
        }
      }
    },
    methods:{
      showPassword(){
        this.show_password = !this.show_password;
      },
      create_room(){
        let data = new FormData();
        data.append("room", this.id)
        data.append("password", this.password)
        data.append("desc", this.desc)
        this.processing = false
        return this.$store.dispatch('rschedule',data).then(()=>{
          // this.done = r.done;
        }).catch(()=>{
          // this.processing = false;
        })
      },
      async login(){
        if(this.processing)return;
        if(this.room==null){await this.create_room()}
        let data = new FormData();
        data.append("password", this.password);
        this.processing = true;
        console.log("dispatch('login_room')",this.id,data.get("password"))
        this.$store.dispatch('login_room',{'room_id':this.id,"userData":data}).then((r)=>{
          console.log(r)
          if(r.login){
            this.$store.state.auth_rooms.push(this.id)
            this.$router.push({ name: 'CallPage', params: { meetingID: this.id }})
          }
          this.processing = false;
        }).catch(()=>{
          this.processing = false;
        })
      },
      redirect_if_auth_home(){
        if(!this.auth){
          setTimeout(() => {
            this.$router.push({ name: 'LoginRD', params: { meetingID: this.id }})
          }, 5000);
        }else{
          this.$store.dispatch('myrooms').then((data)=>{
            if(typeof data.rooms !== 'undefined' && data.rooms != null){
              this.rooms = data.rooms;
            }
          })
        }
      }
    },
    mounted() {
      this.id = this.$route.params.meetingID;
      this.auth = this.$store.state.auth;
      this.auth_loading = this.$store.state.loading.auth;
      if(this.auth_loading){
        this.$store.watch((state)=>state.loading.auth,(auth_loading) => {
          this.auth_loading = auth_loading;
          this.auth = this.$store.state.auth;
          this.redirect_if_auth_home()
        });
      }else{
        this.redirect_if_auth_home()
      }
      this.$store.dispatch('get_room',this.id).then((r)=>{
        if(r.created){
          this.room = {}
        }else{
          this.room = null
        }
      })
    },
    computed: {
      ...mapGetters([
        'lc',
      ])
    }
  }
</script>
<style scoped>

</style>