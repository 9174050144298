export default {
    methods: {
        sendSignalMessage(msg){
            // console.log("wss-s://",msg);
            this.webscoket.send(JSON.stringify(msg))
        },
        handleSingalServerMessage(msg){
            // console.log("wss-r://",msg);
            switch (msg.msg_type) {
            case "init":
                for(let k in msg.data){
                let peer = msg.data[k]
                peer.caller = peer.active && true
                this._setPeer(peer)
                }
                break;
            case "connected":
                for(let k in msg.data){
                let peer = msg.data[k]
                peer.caller = false
                this._setPeer(peer)
                }
                break;
            case "disconnected":
                for(let k in msg.data){
                    let peer = msg.data[k]
                    this.unsetPeer(peer)
                }
                break;
            case "offer":
                this._setPeer(msg.data["peer"],false)
                this.answerWebRTC(msg.data["peer"],msg.data["offer"])
                break;
            case "re-offer":
                this._setPeer(msg.data["peer"],false)
                this.sendWebRTCOffer(msg.data["peer"])
                break;
            case "answer":
                this._setPeer(msg.data["peer"],false)
                this.answerHandleWebRTC(msg.data["peer"],msg.data["answer"])
                break;
            case "icecandidate":
                this.icecandidateHandleWebRTC(msg.data["peer"],msg.data["candidate"])
                break;
            default:
                break;
            }
        },
        setupWebScoket(){
            if(!this.room_value_flag) return;
            let signal_server = '/ws/room/'+encodeURIComponent(this.room_id);
            // this.webscoket = new WebSocket('wss://'+window.location.host+signal_server);
            this.webscoket = new WebSocket('wss://api.c-sm.cc'+signal_server);
            this.webscoket.onopen = ()=>{
            // console.log('onopen',event);
            }
            this.webscoket.onmessage = (event)=>{
            // console.log('onmessage',event);
            try{
                let msg = JSON.parse(event.data)
                // console.log("RCV -- webscoket => ", msg)
                this.handleSingalServerMessage(msg)
            }catch(e){
                /* */
            }
            }
            this.webscoket.onerror = (event)=>{
            console.log('onerror',event);
            }
            
        },
    }
}