import { mdiMicrophone,mdiMicrophoneOff,mdiVideo,mdiVideoOff,mdiMonitorShare,mdiMonitorOff,mdiShare,mdiTuneVertical,mdiFullscreen,mdiFullscreenExit,mdiCloseBox,mdiPhoneHangup } from '@mdi/js';
  import {
    mapGetters
  } from 'vuex'
  import Icon from '../components/Icon.vue'
  import SettingsModal from '../components/SettingsModal.vue'
  import ShareModal from '../components/ShareModal.vue'
export default{
    data(){
        return {
          box_size:"240p",
          debug_mode:false,
          testing_num_boxies:18*6+7,
          boxies_per_screen:12,
          current_page:1,
          room:null,
          room_id:"NOT",
          room_desc:"",
          room_value_flag:false,
          peers:{},
          icons:{mdiMicrophone,mdiMicrophoneOff,mdiVideo,mdiVideoOff,mdiMonitorShare,mdiMonitorOff,mdiShare,mdiTuneVertical,mdiFullscreen,mdiFullscreenExit,mdiCloseBox,mdiPhoneHangup },
          toggels:{
            mic:false,
            video:false,
            persentscreen:false,
            fullscreen:false,
            sharescreen:false,
            settingscreen:false
          },
          permissions:{
            audio:false,
            video:false
          },
          selectedMediaDevices:{
            mic:null,
            video:null,
            speaker:null
          },
          streams:{
            audio:null,
            video:null,
            video_screen:null
          },
          webscoket:null,
          config:null,
          auth:false,
          auth_rooms:null,
          auth_loading:false,
          video_mini_flag:2
        }
      },
      components: {
        Icon,
        SettingsModal,
        ShareModal
      },
      methods:{
        hasHTMLVideo(peer){
          if(
              typeof this.$refs['peer-stream-video-'+peer.info.id] === "undefined" ||
              this.$refs['peer-stream-video-'+peer.info.id].srcObject == null ||
              !this.$refs['peer-stream-video-'+peer.info.id].srcObject.active
             ){
               console.log('hasVideo',peer,""+false)
               return false
             }
          console.log('hasVideo',peer,""+true)
          return true
        },
        peers_len(){
          return Object.keys(this.peers).length;
        },
        requestPermissions(){
          if(!navigator.mediaDevices){
            return false
          }
          let audioPromise,videoPromise;
          audioPromise = navigator.mediaDevices.getUserMedia({ audio: true }).then((stream)=>{
            stream.getTracks().forEach((track)=>{track.stop()})
            this.permissions.audio = true;
          }).catch((err)=>{
            console.log(err)
          });
          videoPromise = navigator.mediaDevices.getUserMedia({ video: true }).then((stream)=>{
            stream.getTracks().forEach((track)=>{track.stop()})
            this.permissions.video = true;
          }).catch((err)=>{
            console.log(err)
          });
          Promise.all([audioPromise,videoPromise]).then(()=>{
            this.updateStreams()
            this.setupWebScoket()
          })
        },
        endMeeting(){
          window.removeEventListener("beforeunload", this.leavePage);
          this.webscoket?.close()
        },
        leavePageBackbutton(){
          this.endMeeting()
        },
        leavePage(e){
          let confirmationMessage = this.lc('common.leave_warning');
          (e || window.event).returnValue = confirmationMessage; //Gecko + IE
          return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        },
        mountMeeting(){
          this.room_id = this.$route.params.meetingID;
          this.room_value_flag = true;
          if(!this.auth){
            /**/
            this.$router.push({ name: 'LoginRD', params: { meetingID: this.room_id }})
          }else if(Array.isArray(this.auth_rooms) && this.auth_rooms.indexOf(this.room_id)<0){
            this.$router.push({ name: 'LoginRoom', params: { meetingID: this.room_id }})
          }else{
            this.$store.dispatch('get_room',this.room_id).then((r)=>{
              this.room = r.room
            })
            this.$store.dispatch('iceConfig').then(config=>this.config=config).then(()=>{
              this.requestPermissions()
            })
          }
        },
        startDeviceWatch(){
            this.$store.watch((state)=>state.selectedMediaDevices,(selectedMediaDevices) => {
                console.log("change state.selectedMediaDevices",selectedMediaDevices)
                this.selectedMediaDevices = selectedMediaDevices
                this.updateStreamsDevices()
            },true)
        },
        startAuthCheck(){
            this.auth = this.$store.state.auth;
            this.auth_rooms = this.$store.state.auth_rooms;
            this.auth_loading = this.$store.state.loading.auth;
            if(this.auth_loading){
                this.$store.watch((state)=>state.loading.auth,(auth_loading) => {
                this.auth_loading = auth_loading;
                this.auth = this.$store.state.auth;
                this.auth_rooms = this.$store.state.auth_rooms;
                this.mountMeeting()
                });
            }else{
                this.mountMeeting()
            }
        },
        startDebug(){
            this.debug_mode = (this.$route.query.debug === "true")
            if(this.debug_mode){
                setInterval(()=>{
                this.updateAllPeerDebugText()
                },1000)
            }
        }
      },
      computed: {
        iceConfig(){
          if(this.config == null){
            return {
              'iceServers': [
                {'urls': 'stun:stun.l.google.com:19302'},
              ]
            }
          }else{
            return this.config
          }
        },
        ...mapGetters([
          'lc',
        ])
      },
}