<template>
  <div class="login-page">
    <NavBar :hideLogin="true"/>
    <section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <form action="" class="box" @submit.prevent="">
            <div class="field">
              <label for="" class="label">{{lc('common.id')}}</label>
              <div class="control has-icons-left">
                <input type="text" class="input" required v-model="id">
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">{{lc('common.password')}}</label>
              <div class="control has-icons-left">
                <input type="password" class="input" required v-model="password">
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="checkbox">
                <input type="checkbox">
               {{lc('common.remember_me')}}
              </label>
            </div>
            <div class="field">
              <button class="button is-success is-fullwidth" :class="{'is-loading':processing}" @click="login()">
                {{lc('auth.login')}}
              </button>
            </div>
            <div class="field">
              <router-link to="/signup" class="button is-light is-fullwidth">{{lc('auth.not_a_user_signup')}}</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>
<script>

  import NavBar from './NavBar.vue'
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'Login',
    components: {
      NavBar
    },
    data(){
      return {
        id:'',
        password:'',
        processing:false
      }
    },
    methods:{
      login(){
        if(this.processing)return;
        var data = new FormData();
        data.append("id", this.id);
        data.append("password", this.password);
        this.processing = true;
        this.$store.dispatch('login',data).then((r)=>{
          console.log(r)
          if(r.login){
            let room_id = this.$route.params.meetingID;
            if(room_id == null){
              this.$router.push('/')
            }else{
              this.$router.push({ name: 'CallPage', params: { meetingID: room_id }})
            }
          }
          this.processing = false;
        }).catch(()=>{
          this.processing = false;
        })
      }
    },
    computed: {
      ...mapGetters([
        'lc',
      ])
    }
  }
</script>
<style scoped>

</style>