<template>
  <div class="meeting-page">
    
    <div class="hero is-fullheight has-background-dark">
      <div class="hero-body">
        <div class="container is-fluid">
          <div class="columns is-flex is-vcentered is-centered is-multiline is-mobile">
            <div v-for="peer in peers" v-bind:key="peer" class="column has-text-centered relative-box" :class="{'is-peer-box':true,'is-3':peers_len()>2,'is_fullscreen':peer.lstatus.full_screen}" :ref="'peer_'+peer.info.id+'_elem'">
              <video autoplay muted :ref="'peer-stream-video-1-'+peer.info.id" :class="{'hide':!peer.lstatus.video_stream_1,'mini':video_mini_flag==1}" @click="video_mini_flag=(video_mini_flag==1)?2:video_mini_flag"></video>
              <video autoplay muted :ref="'peer-stream-video-2-'+peer.info.id" :class="{'hide':!peer.lstatus.video_stream_2,'mini':video_mini_flag==2}" @click="video_mini_flag=(video_mini_flag==2)?1:video_mini_flag"></video>
              <audio :ref="'peer-stream-audio-'+peer.info.id" autoplay style="display:none;"></audio>
              <!-- <h3 :class="{'hide':peer.lstatus.video}">{{peer.info.name}}</h3> -->
              <button class="button is-rounded is-peer-name" :class="{'hide':peer.lstatus.video_stream_1||peer.lstatus.video_stream_2}">{{peer.info.name}}</button>
              <p v-if="debug_mode" class="debug-data" :key="peer.debug_text">{{peer.debug_text}}</p>
              <div class="buttons left-corner" @click="toggelFullscreenPeer(peer.info.id)">
                <Icon :icon="icons.mdiFullscreen" :size="24" v-if="!peer.lstatus.full_screen" />
                <Icon :icon="icons.mdiFullscreenExit" :size="24" v-if="peer.lstatus.full_screen" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar has-background-black-ter	is-fixed-bottom is-flex">
      <div class="navbar-controls start">
        <div class="navbar-control-item"><img src="/assets/logo-sm-dk.svg" width="40"></div>
      </div>
      <div class="navbar-controls center">
        <div class="navbar-control-item">
          <div class="buttons" @click="toggelMic()">
            <Icon :icon="icons.mdiMicrophone" :size="24" v-if="permissions.audio&&toggels.mic"/>
            <Icon :icon="icons.mdiMicrophoneOff" :size="24" v-else/>
          </div>
        </div>
        <div class="navbar-control-item">
          <div class="buttons" @click="toggelVideo()">
            <Icon :icon="icons.mdiVideo" :size="24" v-if="permissions.video&&toggels.video"/>
            <Icon :icon="icons.mdiVideoOff" :size="24" v-else/>
          </div>
        </div>
        <div class="navbar-control-item">
          <div class="buttons" @click="toggelPresentscreen()">
            <Icon :icon="icons.mdiMonitorShare" :size="24" v-if="toggels.persentscreen"/>
            <Icon :icon="icons.mdiMonitorOff" :size="24" v-if="!toggels.persentscreen"/>
          </div>
        </div>
        <div class="navbar-control-item">
          <div class="buttons" @click="toggelEndMeeting()">
            <Icon :icon="icons.mdiPhoneHangup" :size="24" :color="'red'"/>  
          </div>
        </div>
      </div>
      <div class="navbar-controls end">
        <div class="navbar-control-item">
            <div class="buttons" @click="toggelFullscreen()">
              <Icon :icon="icons.mdiFullscreen" :size="24" v-if="!toggels.fullscreen" />
              <Icon :icon="icons.mdiFullscreenExit" :size="24" v-if="toggels.fullscreen" />
            </div>
          </div>
          <div class="navbar-control-item">
            <div class="buttons" @click="toggelShare()">
              <Icon :icon="icons.mdiShare" :size="24" />
            </div>
          </div>
          <div class="navbar-control-item">
            <div class="buttons" @click="toggelSettings()">
              <Icon :icon="icons.mdiTuneVertical" :size="24" />
            </div>
          </div>
      </div>
    </nav>
    <SettingsModal :isactive="toggels.settingscreen" :closeModal="toggelSettings" :permissions="permissions" />
    <ShareModal :isactive="toggels.sharescreen" :closeModal="toggelShare" :roomID="room_id" :room="room" />
  </div>
</template>

<script>
  import WebScoketCallPeerMixin from '../mixins/WebScoketCallPeerMixin'
  import WebRTCCallPeerMixin from '../mixins/WebRTCCallPeerMixin'
  import StreamsFunctiosCallMixin from '../mixins/StreamsFunctiosCallMixin'
  import CommonCallPageMixin from '../mixins/CommonCallPageMixin'
  import("@/assets/call-page.scss");
  export default {
    name: 'CallPage',
    mixins: [WebScoketCallPeerMixin,WebRTCCallPeerMixin,StreamsFunctiosCallMixin,CommonCallPageMixin],
    data(){
      return {}
    },
    methods:{
      startSFUCheck(){
        this.room_id = this.$route.params.meetingID;
        if(this.$store.state.config.sfu == null){
          this.$store.watch((state)=>state.config.sfu,(sfu) => {
            if(sfu){
              this.$router.push({ name: 'CallPageSFU', params: { meetingID: this.room_id }})
            }
          });
        }else if(this.$store.state.config.sfu){
          this.$router.push({ name: 'CallPageSFU', params: { meetingID: this.room_id }})
        }
      }
    },
    mounted() {
      this.startSFUCheck();
      this.startDeviceWatch();
      this.startAuthCheck();
      this.startDebug();
    }
  }
</script>
<style>

</style>