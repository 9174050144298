import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../components/LandingPage.vue'
import Login from '../components/Login.vue'
import LoginRoom from '../components/LoginRoom.vue'
import SignUp from '../components/SignUp.vue'
import Schedule from '../components/Schedule.vue'
import Invite from '../components/Invite.vue'
import CallPage from '../components/CallPage.vue'
import CallPageSFU from '../components/CallPageSFU.vue'
import PageNotFound from '../components/PageNotFound.vue'

const routes = [
  {path: '/',name: 'Home',component: LandingPage},
  {path: '/login',name: 'Login',component: Login},
  {path: '/login/:meetingID',name: 'LoginRD',component: Login},
  {path: '/room/login/:meetingID',name: 'LoginRoom',component: LoginRoom},
  {path: '/signup',name: 'SignUp',component: SignUp},
  {path: '/schedule',name: 'Schedule',component: Schedule},
  {path: '/invite',name: 'Invite',component: Invite},
  {path: '/meeting/:meetingID',name: 'CallPage',component: CallPage},
  {path: '/sfu/meeting/:meetingID',name: 'CallPageSFU',component: CallPageSFU},
  {path: "/:catchAll(.*)",name: 'PageNotFound',component: PageNotFound}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode:'hash',
  routes
})

export default router
