export default{
    methods:{
        setTracks(peer_data,add = false){
            let _video_tracks = []
            let _video_screen_tracks = []
            let _audio_tracks = []
            if(this.streams.video != null){
              _video_tracks = [..._video_tracks, ...this.streams.video.getTracks()];
            }
            if(this.streams.video_screen != null){
              _video_screen_tracks = [..._video_screen_tracks, ...this.streams.video_screen.getTracks()];
            }
            if(this.streams.audio != null){
              _audio_tracks = [..._audio_tracks, ...this.streams.audio.getTracks()];
            }
            console.log("RTC setTracks =>",this.peers[peer_data.id].webrtc_conn,"add = ",add,"tracks = ",[..._video_tracks,..._audio_tracks],"streams = ",this.streams,"peer = ",this.peers[peer_data.id])
            // let localstream = new MediaStream([..._video_tracks,..._audio_tracks,..._video_screen_tracks])
            if(this.peers[peer_data.id].streams.video == null) this.peers[peer_data.id].streams.video = new MediaStream([..._video_tracks])
            if(this.peers[peer_data.id].streams.audio == null) this.peers[peer_data.id].streams.audio = new MediaStream([..._audio_tracks])
            if(this.peers[peer_data.id].streams.video_screen == null) this.peers[peer_data.id].streams.video_screen = new MediaStream([..._video_screen_tracks])
            if(this.toggels.video){
              _video_tracks.forEach((track,i) => {
                if (typeof this.peers[peer_data.id].tracks.video[i] === 'undefined') {
                  this.peers[peer_data.id].tracks.video[i] = this.peers[peer_data.id].webrtc_conn.addTrack(track,this.peers[peer_data.id].streams.video)
                } else {
                  this.peers[peer_data.id].tracks.video[i].replaceTrack(track)
                }
                console.log(`video[${i}].track(${track})`)
              });
            }
            if(this.toggels.mic){
              _audio_tracks.forEach((track,i) => {
                if (typeof this.peers[peer_data.id].tracks.audio[i] === 'undefined') {
                  this.peers[peer_data.id].tracks.audio[i] = this.peers[peer_data.id].webrtc_conn.addTrack(track,this.peers[peer_data.id].streams.audio)
                } else {
                  this.peers[peer_data.id].tracks.audio[i].replaceTrack(track)
                }
                console.log(`audio[${i}].track(${track})`)
              });
            }
            if(this.toggels.persentscreen){
              _video_screen_tracks.forEach((track,i) => {
                if (typeof this.peers[peer_data.id].tracks.video_screen[i] === 'undefined') {
                  this.peers[peer_data.id].tracks.video_screen[i] = this.peers[peer_data.id].webrtc_conn.addTrack(track,this.peers[peer_data.id].streams.video_screen)
                } else {
                  this.peers[peer_data.id].tracks.video_screen[i].replaceTrack(track)
                }
                console.log(`video_screen[${i}].track(${track})`)
              });
            }
            
          },
          registerOtherEvnetsWebRTCConnection(peer_data){
            let event_function_gen = (event_name)=>{
              return (e)=>{
                console.log("webrtc://",event_name,e);
                this.updatePeerDebugText(peer_data);
              };
            }
            let webrtc_events_list = ["negotiationneeded","signalingstatechange","connectionstatechange","iceconnectionstatechange","datachannel","icecandidateerror","icegatheringstatechange"]
            webrtc_events_list.forEach((ie)=>this.peers[peer_data.id].webrtc_conn.addEventListener(ie,event_function_gen(ie)))
          },
          onTrackEvent(peer_data){
            return (e)=>{
              let target_peer_id = (this.$store.state.config.sfu)?e.streams[0].getTracks()[0].label.split("|")[0]:peer_data.id;
              if(!(target_peer_id in this.peers)){
                target_peer_id = peer_data.id
              }
              console.log("RTC track =>",e) // looking for event.candidate
              if(e.track.kind == "video"){
                let video_obj_num = (this.$refs['peer-stream-video-1-'+target_peer_id].srcObject == null)?'1':'2';
                let video_ref = 'peer-stream-video-'+video_obj_num+'-'+target_peer_id
                let video_stream = e.streams[0]
                this.$refs[video_ref].srcObject = video_stream;
                this.peers[target_peer_id].lstatus['video_stream_'+video_obj_num] = true;
                let video_ended_func = (e) => {
                  console.log('ended video event','tracks',e)
                  switch (e.type) {
                    case "ended":
                      this.$refs[video_ref].srcObject = null;
                      video_stream.getTracks()[0].removeEventListener('ended', video_ended_func);
                      video_stream.getTracks()[0].removeEventListener('mute', video_ended_func);
                      video_stream.getTracks()[0].removeEventListener('unmute', video_ended_func);
                      this.peers[target_peer_id].lstatus['video_stream_'+video_obj_num] = false;
                      break;
                    case "mute":
                      this.peers[target_peer_id].lstatus['video_stream_'+video_obj_num] = false;
                      break;
                    case "unmute":
                      this.peers[target_peer_id].lstatus['video_stream_'+video_obj_num] = true;
                      break;
                    default:
                      break;
                  }
                }
                video_stream.getTracks()[0].addEventListener('ended', video_ended_func);
                video_stream.getTracks()[0].addEventListener('mute', video_ended_func);
                video_stream.getTracks()[0].addEventListener('unmute', video_ended_func);
              }else if(e.track.kind == "audio")
                this.$refs['peer-stream-audio-'+target_peer_id].srcObject = e.streams[0];
            }
          },
          initWebRTC(peer_data){
            console.log("RTC init start => ",this.peers[peer_data.id],this.peers[peer_data.id].caller)
            let peerConnectionConfig = this.iceConfig;
            // console.log("ice servers",peerConnectionConfig)
            console.log("RTC => init 0",this.peers[peer_data.id],peerConnectionConfig)
            this.peers[peer_data.id].webrtc_conn = new RTCPeerConnection(peerConnectionConfig);
            console.log("RTC => init 1",this.peers[peer_data.id])
            this.registerOtherEvnetsWebRTCConnection(peer_data);
            this.peers[peer_data.id].webrtc_conn.addEventListener('negotiationneeded',(e)=>{
              console.log("negotiationneeded ",this.peers[peer_data.id].caller,e) // looking for event.candidate
              this.sendWebRTCOffer(peer_data)
            })
            this.peers[peer_data.id].webrtc_conn.addEventListener('icecandidate',e=>{
              // console.log("icecandidate",e) // looking for event.candidate
              this.sendSignalMessage({
                  "msg_type":"icecandidate",
                  "data":{"peer":this.peers[peer_data.id].info,"candidate":e.candidate}
              })
            })
            // peer.webrtc_conn.onicecandidate = 
            this.peers[peer_data.id].webrtc_conn.addEventListener('track',this.onTrackEvent(peer_data))
            this.peers[peer_data.id].webrtc_conn.createDataChannel("dump");//ice candnidates not firing fix
            this.updateStreams().then(()=>{
              this.setTracks(peer_data,true)
            })
            console.log("RTC => init complete",this.peers[peer_data.id])
          },
          sendWebRTCOffer(peer_data){
            this.peers[peer_data.id].lstatus.offer_in_progress = true;
            this.peers[peer_data.id].webrtc_conn.createOffer().then((sdp)=>{
              // if (this.peers[peer_data.id].webrtc_conn != "stable" && this.peers[peer_data.id].webrtc_conn != "new") return;
              this.peers[peer_data.id].webrtc_conn.setLocalDescription(sdp).then(()=>{
                  this.sendSignalMessage({
                      "msg_type":"offer",
                      "data":{"peer":this.peers[peer_data.id].info,"offer":sdp}
                  })
                })
            }).catch((err)=>{
              console.log("error offer creation",err)
            });
          },
          async answerWebRTC(peer_data,offer){
            console.log('answerWebRTC',peer_data,this.peers[peer_data.id].webrtc_conn,offer)
            let offerCollisionFlag = this.peers[peer_data.id].lstatus.offer_in_progress && this.peers[peer_data.id].webrtc_conn.signalingState != "stable";
            if (peer_data.id == 'server_agent' && this.peers[peer_data.id].webrtc_conn.signalingState === "have-local-offer"){
              await this.peers[peer_data.id].webrtc_conn.setLocalDescription({ type: "rollback" })
              // this.peers[peer_data.id].lstatus.offer_in_progress = false
            }else if(offerCollisionFlag && this.peers[peer_data.id].caller)return
            
            this.peers[peer_data.id].webrtc_conn.setRemoteDescription(offer).then(()=>{
              this.peers[peer_data.id].webrtc_conn.createAnswer().then(sdp=>{
                this.peers[peer_data.id].webrtc_conn.setLocalDescription(sdp)
                console.log("createAnswer",sdp)
                this.sendSignalMessage({
                    "msg_type":"answer",
                    "data":{"peer":peer_data,"answer":sdp}
                })
              }).catch(e=>console.log("answerWebRTC-1 -- error",e))
            }).catch(e=>console.log("answerWebRTC-0 -- error",e))
          },
          answerHandleWebRTC(peer_data,answer){
            console.log("answerHandleWebRTC",peer_data,answer)
            this.peers[peer_data.id].webrtc_conn.setRemoteDescription(answer).then(()=>{
              this.peers[peer_data.id].lstatus.offer_in_progress = false;
              // console.log("answerHandleWebRTC -- done",peer,answer)
            }).catch(e=>console.log("answerHandleWebRTC -- error",e,e.type));
          },
          icecandidateHandleWebRTC(peer_data,answer){
            // console.log("icecandidateHandleWebRTC",peer_data,answer)
            this.peers[peer_data.id].webrtc_conn.addIceCandidate(answer).catch(e=>{console.log("addIceCandidate.error",e)});
          },
          initPeer(info){
            return {
              info:info,
              caller:false,
              has_tracks:true,
              webrtc_conn:null,
              lstatus:{
                video_stream_1:false,
                video_stream_2:false,
                offer_in_progress:false,
                full_screen:false
              },
              tracks:{
                video:[],//the video stream have multiple tracks so does the audio
                audio:[],
                video_screen:[]
              },
              streams:{
                video:null,//this to make sure that media controls are kept together
                audio:null,
                video_screen:null
              },
              debug_text:"DEBUG"
            }
          },
          _setPeer(info){
            this.setPeer(info)
          },
          setPeer(info){
            let is_init = !(info.id in this.peers)
            // console.log('setPeer(',info,',',is_init,')')
            if(is_init){
              this.peers[info.id]=this.initPeer(info);
              this.peers[info.id].caller = info.caller;
            }else{
              this.peers[info.id].info = info
            }
            if (info.id ==="server_agent"){
              this.peers[info.id].caller = false;
            }
            let is_init_rtc = this.peers[info.id].webrtc_conn == null
            if(is_init_rtc && info.active){
              this.initWebRTC(info)
            }
          },
          unsetPeer(peer_data){
            this.peers[peer_data.id].webrtc_conn.close()
            this.peers[peer_data.id] = this.initPeer(peer_data)
          },
          updateAllPeerDebugText(){
            for(let k in this.peers){
              this.updatePeerDebugText(this.peers[k].info)
            }
          },
          updatePeerDebugText(peer_data){
            if(this.peers[peer_data.id].webrtc_conn!==null){
              this.peers[peer_data.id].webrtc_conn.getStats().then(_states=>{
                let states = [];
                _states.forEach(_sr=>states.push(_sr))
                let remote_candidate = states.find(sr=>sr.type == 'remote-candidate')
                let local_candidate = states.find(sr=>sr.type == 'local-candidate')
                let candidate_pair = states.find(sr=>sr.type == 'candidate-pair')
                this.peers[peer_data.id].debug_text = 
                `signling=${this.peers[peer_data.id].webrtc_conn.signalingState} /connection=${this.peers[peer_data.id].webrtc_conn.connectionState}
                ice_Connection=${this.peers[peer_data.id].webrtc_conn.iceConnectionState}/Gathering=${this.peers[peer_data.id].webrtc_conn.iceGatheringState}
                senders=${this.peers[peer_data.id].webrtc_conn.getSenders().length} /receivers=${this.peers[peer_data.id].webrtc_conn.getReceivers().length}
                isCaller=${this.peers[peer_data.id].caller} /isActive=${this.peers[peer_data.id].info.active}
                bytes(${candidate_pair.currentRoundTripTime}): ${this.humanReadableBytes(candidate_pair.bytesSent)}/${this.humanReadableBytes(candidate_pair.bytesReceived)}
                local-IP: ${local_candidate.ip}:${local_candidate.port}/${local_candidate.protocol}
                remote-IP: ${remote_candidate.ip}:${remote_candidate.port}/${remote_candidate.protocol}
                `;
              }).catch(()=>{
                /**/
                this.peers[peer_data.id].debug_text = 
                `signling=${this.peers[peer_data.id].webrtc_conn.signalingState} /connection=${this.peers[peer_data.id].webrtc_conn.connectionState}
                ice_Connection=${this.peers[peer_data.id].webrtc_conn.iceConnectionState}/Gathering=${this.peers[peer_data.id].webrtc_conn.iceGatheringState}
                senders=${this.peers[peer_data.id].webrtc_conn.getSenders().length} /receivers=${this.peers[peer_data.id].webrtc_conn.getReceivers().length}
                isCaller=${this.peers[peer_data.id].caller} /isActive=${this.peers[peer_data.id].info.active}
                `;
              })
            }else{
              this.peers[peer_data.id].debug_text = 
                `isCaller=${this.peers[peer_data.id].caller} /isActive=${this.peers[peer_data.id].info.active}
                offer_in_progress=${this.peers[peer_data.id].lstatus.offer_in_progress}`;
            }
          },
          humanReadableBytes(size) {
            var i = Math.floor( Math.log(size) / Math.log(1024) );
            return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        }
    }
}