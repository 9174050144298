<template>
  <div class="landing-page">
    <NavBar :hideSignUp="true" :hideLogin="auth" :showLogout="auth" />
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 is-offset-3">
              <div class="field has-addons is-large">
                <div class="control is-expanded">
                  <input class="input" type="text" v-model="roomNumber" :placeholder="lc('landing.metting_placeholder')">
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="field is-grouped is-grouped-centered is-large">        
                <div class="control">
                  <a class="button is-info" @click="startMeeting()">
                    {{lc('landing.join_metting')}}
                  </a>
                </div>
                <div class="control">
                  <router-link to="/schedule" class="button is-light">{{lc('common.schedule')}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="content has-text-centered">
          <p>
            {{lc('footer.message')}}
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import NavBar from './NavBar.vue'
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'LandingPage',
    data(){
      return {
        roomNumber:this.randomMeetingID(),
        auth:false,
      }
    },
    components: {
      NavBar
    },
    methods:{
      randomMeetingID(){
        let numbers_lenght = 4;
        let letters_length = 7
        let numbers = '123456789';
        let letters = 'abcdefghijklmnopqrstuvwxyz';
        let result = '';
        for (let i = numbers_lenght; i > 0; --i) result += numbers[Math.floor(Math.random() * numbers.length)];
        result += '-';
        for (let i = letters_length; i > 0; --i) result += letters[Math.floor(Math.random() * letters.length)];
        return result;
      },
      startMeeting(){
        this.$router.push({ name: 'CallPage', params: { meetingID: this.roomNumber }})
      }
    },
    mounted(){
      this.auth = this.$store.state.auth;
      this.$store.watch((state)=>state.auth,(auth) => {
       this.auth = auth;
      });
    },
    computed: {
      ...mapGetters([
        'lc',
      ])
    }
  }
</script>
<style>
  .cus-mt-35vh {
    margin-top: 35vh !important;
  }

  /* .landing-page {
    overflow: hidden;
  } */
</style>