import { createStore } from 'vuex'
const axios = require('axios');
var _ = require('lodash');
import locals from '@/locals'
let selectedLocal = localStorage.getItem('local') || 'ar';
if(!locals[selectedLocal]) selectedLocal = 'ar'

let api = axios.create({
  baseURL: 'https://api.c-sm.cc/api',
  timeout: 15000,
  withCredentials: true,
});
let api_error_handler = (state,e)=>{
  state.server_down = true;
  console.log('api_error_handler',e)
}
export default createStore({
  state: {
    selectedLocal:selectedLocal,
    isRTL:locals[selectedLocal]?.isRTL || false,
    usedLabels:{},
    config:{
      "logicserver": null,
      "realm": null,
      "registration": false,
      "tls": null,
      "sfu": null
    },
    loading:{
      auth:false
    },
    auth:false,
    auth_rooms:[],
    user:{},
    selectedMediaDevices:{
      mic:null,
      video:null,
      speaker:null,
      update_flage:false
    },
    server_down:false
  },
  mutations: {
    changeLocal(state,local){
      state.selectedLocal = local
      state.isRTL = locals[selectedLocal]?.isRTL || false
      localStorage.setItem('local',local)
    },
    changeMediaDevices(state,selectedMediaDevices){
      console.log("state->changeMediaDevices",selectedMediaDevices)
      state.selectedMediaDevices = selectedMediaDevices
      state.selectedMediaDevices.update_flage = !state.selectedMediaDevices.update_flage
    },
    configs(state){
      api.get('/config').then((r)=>r.data).then((serverConfig)=>{
        state.config = serverConfig
        state.server_down = false
      }).catch(e=>api_error_handler(state,e))
    },
    auth(state){
      state.loading.auth = true
      api.get('/auth').then((r)=>r.data).then((authData)=>{
        state.auth = authData.auth;
        state.auth_rooms = authData.rooms;
        if(state.auth){
          state.user = authData.user
        }
        state.loading.auth = false
      }).finally(()=>{
        state.loading.auth = false
      }).catch(e=>api_error_handler(state,e))
    },
  },
  getters: {
    local (state) {
      return locals[state.selectedLocal]
    },
    lc: (state) => (label) => {
      // console.log('getting label',label)
      state.usedLabels[label] = _.get(locals[state.selectedLocal], label, label)
      return state.usedLabels[label];
    },
    config: (state) => () =>{
      return state.config;
    },
    selectedMediaDevices:(state) => () =>{
      return state.selectedMediaDevices;
    }
  },
  actions: {
    login(context,userData){//id,password
      return api.post('/login',userData).then((r)=>{
        if(r.data.login){
          context.state.auth = r.data.login;
          context.state.user = r.data.user;
        }
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    login_room(context,{room_id,userData}){
      console.log("login_room",room_id,userData)
      return api.post(`/room/login/${room_id}`,userData).then((r)=>{
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    get_room(context,room_id){//id,password
      return api.get(`/rschedule/${room_id}`).then((r)=>{
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    logout(context){
      return api.get('/logout').then((r)=>{
        context.state.auth = false;
        context.state.user = {};
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    register(context,userData){//id,name,email,password
      return api.post('/register',userData).then((r)=>{
        if(r.data.auth){
          context.state.auth = r.data.auth;
          context.state.user = r.data.user;
        }
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    rschedule(context,userData){//id,password,desc
      return api.post('/rschedule',userData).then((r)=>{
        if(r.data.auth){
          context.state.auth = r.data.auth;
          context.state.user = r.data.user;
        }
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    invite(context,userData){//id,password,desc
      return api.post('/invetation',userData).then((r)=>{
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    myrooms(context){
      return api.get('/myrooms').then((r)=>{
        return r.data
      }).catch(e=>api_error_handler(context.state,e))
    },
    iceConfig(context){
      return api.get('/turn_token').then(r=>r.data).catch(e=>api_error_handler(context.state,e))
    }
  },
  modules: {
  }
})
